.container {
    /*background-color: #083EA7; /* For browsers that do not support gradients */
    /*background-image: linear-gradient(to right, #05235E , #083EA7);
    /*background-image: linear-gradient(to right, #05235E , #98b4eb);*/
    /* padding-top: 138px; */
    /* min-height: 100vh; */
    background-image: url("./assets/neon_harv.jpeg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    height: 100vh;
    display: flex;
}

.loginTxt {
    font-size: 18px;
    margin-top: 20px;
}

.box {
    display: flex;
    background-color: whitesmoke;
    width: 330px;
    height: 480px;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    padding: 50px;
    margin: auto;
    box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.3), 0px 10px 10px rgba(0, 0, 0, 0.14);
}

.form {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: space-between;
}

.txt {
    font-size: 0.75rem;
    line-height: 13px;
    color: #8A8A8A;
    margin-top: 15px;
    margin-bottom: 5px;
}

.txt_link {
    color: #083EA7;
    align-self: flex-start;
    font-size: 12px;
    line-height: 13px;
}

.forminput>input[type="text"] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    font-size: 1rem;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.forminput>input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0px 30px 0px;
    font-size: 1rem;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

/* .forminput > button[type="submit"] {
    background-color: #083EA7;
    border: none;
    color: white;
    padding: 0.85rem 2rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 14px 1px;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    width: 100%;
    outline: none;
  } */


/* Input fields */
.inputError {
    border: 1px solid red !important;
    background-color: #ffe5e5;
}

.errorText {
    color: red;
    font-size: 0.9rem;
    margin-top: 5px;
}

.apiError {
    color: red;
    font-size: 1rem;
    margin-top: 15px;
    text-align: center;
    font-weight: bold;
}