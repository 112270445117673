/* Background Styling */


/* Table Wrapper */
.mainTableDef {
  width: 1200px;
  max-height: 500px;
  overflow: auto;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 15px;
}

/* Main Table */
.tableIO_main {
  width: 100%;
  border-collapse: collapse;
  font-family: "Arial", sans-serif;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
}

/* Table Header - Softer Look */
.tableIO_main th {
  background-color: #3a3a55; /* Softer than black */
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  padding: 12px;
  font-weight: 600;
}

/* Table Cells */
.tableIO_main td {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  color: #333;
  white-space: nowrap;       /* Prevent line break */
  /* overflow: hidden;        */
  text-overflow: ellipsis;   /* Add ellipsis when content overflows */
  max-width: 200px; 
}

/* Alternate Row Styling */
.tableIO_main tr:nth-child(even) {
  background-color: #f8f8f8;
}

/* Hover Effect */
.tableIO_main tr:hover {
  background-color: #eaeaea;
  transition: background-color 0.2s ease-in-out;
}

/* Input Row Styling */
.rowInput {
  padding: 6px;
  font-size: 14px;
  width: 80px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fafafa;
}

/* Async Button Styling */
.asyncButton {
  background: #704cb6;
  color: white;
  border: none;
  padding: 8px 14px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
}

.asyncButton:hover {
  background-color: #5d3a9e;
  transform: scale(1.05);
}

.asyncButton:active {
  background-color: #4a2f80;
}
