@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&family=Poppins:wght@500;600&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif; /* Clean & modern */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom, #f2f2f5, #e6eaf0);
  box-sizing: border-box;
  /* color: #333;  Softer than pure white for better readability */
  color: whitesmoke
}

/* Headings and Special UI Elements */
h1, h2, h3, div, p .top {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color:black
}

a:active {
  color: inherit;
  font-weight: normal;
  text-decoration: none;
}

.no-active {
  color: inherit !important;
  font-weight: normal !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
