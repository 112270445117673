.nav {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 180px;
  padding: 0px;
  background-color: rgba(33,36,35, 0.2);
  border-left: 1px solid rgba(53,62,66, 0.8);
  opacity: 0.8;
  padding-top: 12px;
  /* position: absolute; */
  /* top: 80px;
  left: 0px; */
  height: 550px;
  border-radius: 0px 0px 4px 0px;
}

.nav_NexSci {
  position: absolute;
  bottom: 25px;
  right: 50px
}

.nav-hide {
  display: none;
}

.navRed {
  /*background-color: red;*/
}

.nav a {
  list-style: none;
  padding: 17px 0px;
  /*width: 100%;*/
  font-size: 14px;
  color: white;
  margin: 0px;
  line-height: 16px;
  font-weight: 300;
  /*transition: all 200ms;*/
  border-radius: 0px 9px 3px 0px;
}

.div_a {
  margin: 0px;
  padding: 0px;
}

.nav a:hover {
  background-color: rgba(53,62,66, 0.8);
  opacity: 0.8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
 
}

.nav .active {
  background-color: rgba(53,62,66, 0.8);
  opacity: 0.8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* border-radius: 0px 10px 10px 0px; */
}

/*
@media only screen and (max-width: 710px) {
  .nav {
    display: none;
    flex-direction: column;
  }
 }
*/
